import "./home.styles.scss";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import particleOptions from "../../utils//particlesOptions.js";
const Home = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const handleButtonClick = () => {
    document
      .getElementById("about-container")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="home-container" id="home-container">
      <Particles
        className="particles"
        id="tsparticles"
        init={particlesInit}
        options={particleOptions}
      />
      <div className="home-desc">
        <h1>
          Hello, I'm <span className="name-span">Daniel</span>.
        </h1>
        <h1>I'm a Front-End Developer.</h1>
        <button onClick={handleButtonClick}>View more about me</button>
      </div>
    </div>
  );
};

export default Home;
