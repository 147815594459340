import "./about.styles.scss";

const About = () => {
  return (
    <div className="about-container" id="about-container">
      <h1>About</h1>
      <p>Device width: {window.innerWidth}px</p>   </div>
  );
};

export default About;
