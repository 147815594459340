import "./nav-bar.styles.scss";
import { useState, useEffect } from "react";
import { Squash as Hamburger } from "hamburger-react";

const buttons = [
  {
    id: 0,
    name: "home",
  },
  {
    id: 1,
    name: "about",
  },
  {
    id: 2,
    name: "sample projects",
  },
  {
    id: 3,
    name: "contact",
  },
];

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuBarFloating, setIsMenuBarFloating] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const aboutContainer = document.getElementById("about-container");
      let aboutElementTop = 0;
      if (aboutContainer) {
        const aboutRect = aboutContainer.getBoundingClientRect();
        if (typeof aboutRect["top"] !== "function") {
          aboutElementTop = aboutRect["top"];
          setIsMenuBarFloating(aboutElementTop <= 2 ? true : false);
        }
      } else {
        console.log("nav-bar-error: Cannot find about container.");
      }
    });

    return () =>
      window.removeEventListener("scroll", () =>
        console.log("window event removed")
      );
  });

  const handleMenuButtonClick = (clickedId) => {
    switch (clickedId) {
      case 0:
        document
          .getElementById("home-container")
          .scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        document
          .getElementById("about-container")
          .scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        document
          .getElementById("sample-projects-container")
          .scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        document
          .getElementById("contact-container")
          .scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }

    if (window.innerWidth <= 600) {
      console.log(window.innerWidth);
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className="nav-container">
      <div
        className={`nav-bar-container ${
          isMenuBarFloating ? "bar-floating" : ""
        }`}
        id="nav-bar-container"
      >
        <div className="buttons-container">
          {buttons.map((button) => (
            <button
              key={button.id}
              onClick={() => handleMenuButtonClick(button.id)}
            >
              {button.name}
            </button>
          ))}
        </div>
        <div className="hamburger-container">
          <Hamburger toggled={isOpen} toggle={setIsOpen} />
        </div>
      </div>
      <div
        className={`nav-drawer-container ${isOpen ? "drawer-visible" : ""} ${
          isMenuBarFloating ? "drawer-floating" : ""
        }`}
      >
        {buttons.map((button) => (
          <button
            key={button.id}
            onClick={() => handleMenuButtonClick(button.id)}
          >
            {button.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NavBar;
