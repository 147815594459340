import "./App.scss";

//components
import Home from "./components/home/home.component";
import NavBar from "./components/nav-bar/nav-bar.component";
import SampleProjects from "./components/sample-projects/sample-projects.component";
import About from "./components/about/about.component";
import Contact from "./components/contact/contact.component";

function App() {
  return (
    <div className="app-container">
      <Home />
      <NavBar />
      <About />
      <SampleProjects />
      <Contact />
    </div>
  );
}

export default App;
