import "./sample-projects.styles.scss";

import ProjectCard from "../project-card/project-card.component";

const projectsData = [
  {
    id: 0,
    name: "Social Media Clone",
    description: "Mock Social Medie application",
    frameworks: ["React.js", "Node.js"],
    path: "https://mock-fb.daniel-olkowski.pl/",
  },
];

const SampleProjects = () => {
  return (
    <div className="sample-projects-container" id="sample-projects-container">
      <h1>My Sample Projects</h1>
      <div className="project-cards-container">
        {projectsData.map((project) => (
          <ProjectCard
            key={project.id}
            name={project.name}
            description={project.description}
            frameworks={project.frameworks}
            path={project.path}
          />
        ))}
      </div>
    </div>
  );
};

export default SampleProjects;
