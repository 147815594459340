import "./project-card.styles.scss";

const ProjectCard = ({ name, description, frameworks, path }) => {
  return (
    <div
      className="project-card-container"
      onClick={() => window.location.replace(path)}
    >
      <h2>{name}</h2>
      <p>{description}</p>
      <p>
        Frameworks used:{" "}
        {frameworks.map((framework) => (
          <span>{framework} </span>
        ))}
      </p>
    </div>
  );
};

export default ProjectCard;
