import "./contact.styles.scss";

const Contact = () => {
  return (
    <div className="contact-container" id="contact-container">
      <h1>Contact</h1>
    </div>
  );
};

export default Contact;
